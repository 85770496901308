$(function () {
    $('.main-slider').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        draggable: true,
        fade: true,
        speed: 1000,
        arrows: false,
        dots: true,
        slide: '.slide'
    });
    $('.main-slider .arrows .next').click(function(){
        $(".main-slider").slick('slickNext');
    });
    $('.main-slider .arrows .prev').click(function(){
        $(".main-slider").slick('slickPrev');
    });



    $('.references-slider').slick({
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        slide: '.slide',
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });
    $('section.references .arrows .next').click(function(){
        $(".references-slider").slick('slickNext');
    });
    $('section.references .arrows .prev').click(function(){
        $(".references-slider").slick('slickPrev');
    });


    $('.mobile-menu-opener').click(function () {
       $('header').toggleClass('menu-active');
    });

});